var state = {
  forms: [],
  activeForm: [],
  activeTabForFields: 'elements',
  themingVars: {
    globalFontFamily: 'Arial',
    globalFontColor: '#777777',
    globalLinkColor: '#206C92',
    globalFontSize: '16',
    labelFontWeight: '500',
    labelFontSize: '16',
    labelMarginBottom: '10',
    helpTextColor: '#cccccc',
    helpTextFontSize: '12',
    helpTextMarginTop: '10',
    inputBorderRadius: '4',
    inputBorderColor: '#dcdfe6',
    inputHoverBorderColor: '#c0c4cc',
    inputFocusBorderColor: '#000000',
    inputShadowColor: '#9D9D9D',
    buttonBackground: '#000000',
    buttonBorderColor: '#000000',
    buttonColor: '#FFFFFF'
  }
};
var mutations = {
  SET_FORMS: function SET_FORMS(state, forms) {
    state.forms = forms;
  },
  SET_ACTIVEFORM: function SET_ACTIVEFORM(state, forms) {
    state.activeForm = forms;
  },
  SET_ACTIVETABFORFIELDS: function SET_ACTIVETABFORFIELDS(state, forms) {
    state.activeTabForFields = forms;
  },
  SET_THEMINGVARS: function SET_THEMINGVARS(state, forms) {
    state.themingVars = forms;
  }
};
var actions = {
  setForms: function setForms(_ref, forms) {
    var commit = _ref.commit;
    commit('SET_FORMS', forms);
  },
  setActiveForm: function setActiveForm(_ref2, forms) {
    var commit = _ref2.commit;
    commit('SET_ACTIVEFORM', forms);
  },
  setActiveTabForFields: function setActiveTabForFields(_ref3, forms) {
    var commit = _ref3.commit;
    commit('SET_ACTIVETABFORFIELDS', forms);
  },
  setThemingVars: function setThemingVars(_ref4, forms) {
    var commit = _ref4.commit;
    commit('SET_THEMINGVARS', forms);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};