import hasPermission from '@/utils/permission.js';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var isHasPermission = hasPermission(value);

    if (!isHasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};