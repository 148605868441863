//
//
//
//
//
//
export default {
  name: 'NumberInput',
  data: function data() {
    return {
      number: 10
    };
  }
};