import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FormBuilder } from '@/components/form_elements/formbuilder';
export default {
  name: 'Publish',
  store: ['themingVars'],
  components: FormBuilder.$options.components,
  props: {
    forms: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    cssProps: function cssProps() {
      // Return an object that will generate css properties key
      // to match with the themingVars
      //
      // Example output: { '--theme-primary-color': this.themingVars.primaryColor }
      var result = {},
          themingVars = this.themingVars;

      for (var v in themingVars) {
        if (themingVars.hasOwnProperty(v)) {
          var newV = '--theme-' + _.kebabCase(v),
              suffix = ''; // Add px to the value if the default value contain 'px'


          if (_.includes(newV, 'size')) suffix = 'px';else if (_.includes(newV, 'margin')) suffix = 'px';else if (_.includes(newV, 'radius')) suffix = 'px';
          result[newV] = themingVars[v] + suffix;
        }
      }

      return result;
    }
  }
};