var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.currentField.defaultValue,
            callback: function($$v) {
              _vm.$set(_vm.currentField, "defaultValue", $$v)
            },
            expression: "currentField.defaultValue"
          }
        },
        _vm._l(_vm.currentField.options, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("el-checkbox", { attrs: { label: item.optionValue } }, [
                _vm._v("\n        " + _vm._s(item.optionLabel) + "\n      ")
              ])
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }