var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "请选择" },
          model: {
            value: _vm.currentField.defaultValue,
            callback: function($$v) {
              _vm.$set(_vm.currentField, "defaultValue", $$v)
            },
            expression: "currentField.defaultValue"
          }
        },
        _vm._l(_vm.currentField.options, function(item) {
          return _c("el-option", {
            key: item.optionValue,
            attrs: { value: item.optionValue, label: item.optionValue }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }