import request from '@/utils/request';
export function getUserInfo() {
  return request({
    url: '/sys/user/info',
    method: 'get'
  });
}
export function userPassword(data) {
  return request({
    url: '/sys/user/password',
    method: 'post',
    params: data
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'DELETE'
  });
}
export function create(data) {
  return request({
    url: 'sys/user/save',
    method: 'post',
    data: data
  });
}
export function update(data) {
  return request({
    url: 'sys/user/update',
    method: 'post',
    data: data
  });
} // 分页获取

export function getList(data) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: data
  });
}
export function get(data) {
  return request({
    url: '/sys/user/info/' + data,
    method: 'get'
  });
}
export function del(data) {
  return request({
    url: '/sys/user/delete/',
    method: 'post',
    data: data
  });
}
export function password(data) {
  return request({
    url: '/sys/user/password/',
    method: 'post',
    params: {
      data: data
    }
  });
}
export function getUserLog() {
  return request({
    url: '/sys/user/log/',
    method: 'get'
  });
}