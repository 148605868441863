//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Checkbox',
  props: ['currentField'],
  data: function data() {
    return {// checkList: [0]
    };
  }
};