import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import Cookies from 'js-cookie';
var TokenName = 'tokenName';
var TokenKey = 'tokenValue';
import store from '@/store';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function removeTokenName() {
  return Cookies.remove(TokenName);
}
export function getTokenName() {
  return Cookies.get(TokenName);
}
export function setTokenName(tokenName) {
  return Cookies.set(TokenName, tokenName);
}
/**
 * @description 权限检测函数
 * @param permissionRoles {Array<string>} 规则，例子数据：['admin','editor']
 * @param defaultPermission {boolean} 默认权限
 * @return {boolean} 权限校验是否通过
 */

export function checkPermission() {
  var permissionRoles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var defaultPermission = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var hasPermission = false; // const roles = store.getters && store.getters.roles

  var userPermissions = store.getters && store.getters.permissions; // 数据类型检测

  if (!Array.isArray(permissionRoles)) {
    console.error("checkPermission \u6743\u9650\u53C2\u6570\u7C7B\u578B\u9519\u8BEF\uFF0C\u4F8B\u5B50\uFF1A['admin','editor']");
    return hasPermission;
  } // 当没有权限时候，使用默认规则


  if (permissionRoles.length === 0) {
    return defaultPermission;
  }

  hasPermission = userPermissions.some(function (role) {
    return permissionRoles.includes(role);
  });
  return hasPermission;
}