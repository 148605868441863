import store from '@/store';
import { getToken, getTokenName, removeToken, removeTokenName } from '@/utils/auth';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import Cookies from 'js-cookie'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  withCredentials: true,
  // 跨域请求时发送 cookies
  timeout: 5000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  console.log(config);
  console.log(process.env.VUE_APP_BASE_API); // Do something before request is sent

  if (store.getters.token) {
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers[getTokenName()] = getToken();
  }

  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
 */

/**
 * 下面的注释为通过在response里，自定义code来标示请求状态
 * 当code返回如下情况则说明权限有问题，登出并返回到登录页
 * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
 * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
 */
function (response) {
  var res = response.data;

  if (res.code !== 0) {
    //强制修改密码
    if (res.code === 2) {
      MessageBox.alert(res.msg, '警告', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        console.info('需要修改密码');
      });
    } else if (res.code === 1) {
      removeTokenName();
      removeToken();
      location.reload();
    } else {
      Message({
        message: res.msg || 'error',
        type: 'error',
        duration: 5 * 1000
      });
    }

    return Promise.reject(res.msg || 'error');
  } else {
    return res.data;
  }
}, function (error) {
  console.error('err' + error); // for debug

  if (error.response) {
    var res = error.response.data;

    if (error.response.status === 401) {
      // 用户登录界面提示
      Cookies.set('point', 401);
      location.reload();
    } else if (res.code) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      });
    }
  } else {
    if (error.message.indexOf('timeout of') === 0) {
      Message({
        message: '请求超时，请检查网络或重试。',
        type: 'error',
        duration: 5 * 1000
      });
    } else if (error.message.indexOf('Network Error') === 0) {
      Message({
        message: '网络错误。',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
    }
  }

  return Promise.reject(error);
});
export default service;