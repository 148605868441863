import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
var bus = new Vue();
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import router from "./router";
import store from "./store";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/errorLog"; // error log

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

Vue.component('Pagination', Pagination);
import BackToTop from '@/components/BackToTop';
Vue.component('BackToTop', BackToTop);
import Preview from '@/views/from/components/preview';
Vue.component('Preview', Preview);
import * as filters from "./filters"; // global filters
// import { mockXHR } from '../mock' // simulation data

import permission from '@/directive/permission/index.js'; // 权限判断指令

import checkPer from '@/utils/checkPermission';
Vue.use(permission);
Vue.use(checkPer);
import { addDateRange, parseTime } from '@/utils/date';
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.parseTime = parseTime; // mock api in github pages site build

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(Element, {
  size: Cookies.get('size') || 'mini'
});
Vue.prototype.bus = bus; // register global utility filters.

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
var vm = new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
global.vm = vm;