var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "demo-image__preview" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "imgtips" }, [
        _vm._v(
          "选择图片（最多" +
            _vm._s(_vm.currentField.imglength) +
            "张），单张图片最大4M"
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgdiv" }, [
      _c("i", { staticClass: "el-icon-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }