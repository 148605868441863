import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
var tagsView = defaultSettings.tagsView,
    fixedHeader = defaultSettings.fixedHeader,
    sidebarLogo = defaultSettings.sidebarLogo,
    showRightPanel = defaultSettings.showRightPanel,
    uniqueOpened = defaultSettings.uniqueOpened,
    settingBtn = defaultSettings.settingBtn;
var state = {
  theme: variables.theme,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  showRightPanel: showRightPanel,
  uniqueOpened: uniqueOpened,
  settingBtn: settingBtn
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};