import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import _defineProperty from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pathToRegexp from 'path-to-regexp';
import { mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  computed: _objectSpread({}, mapGetters(['permission_routes'])),
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var _this = this;

      var matched = this.$route.matched.filter(function (item) {
        return item.name;
      });
      var first = matched[0];

      if (first && first.name.trim().toLocaleLowerCase() !== 'Dashboard'.toLocaleLowerCase()) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: '首页'
          }
        }].concat(matched);
      }

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      }); // add by sunlc 2021年7月23日 当只有一个子菜单时，不显示父菜单名称

      if (this.levelList.length > 2) {
        var parent = this.permission_routes.find(function (item) {
          if (item.path === _this.levelList[1].path) {
            return item;
          } else {
            return false;
          }
        });

        if (parent) {
          var showingChildren = parent.children.filter(function (item) {
            if (item.hidden) {
              return false;
            } else {
              // Temp set(will be used if only has one showing child)
              _this.onlyOneChild = item;
              return true;
            }
          });

          if (showingChildren.length === 1) {
            this.levelList.splice(1, 1);
          }
        }
      }

      console.log('=========this.levelList========', this.permission_routes, this.$route.matched, this.levelList, 50);
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    }
  }
};