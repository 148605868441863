//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'GenderButton',
  props: ['currentField'],
  data: function data() {
    return {// radio: '1'
    };
  }
};