var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  tokenName: function tokenName(state) {
    return state['system/user'].tokenName;
  },
  token: function token(state) {
    return state['system/user'].token;
  },
  user: function user(state) {
    return state['system/user'].user;
  },
  avatar: function avatar(state) {
    return state['system/user'].avatar;
  },
  roles: function roles(state) {
    return state['system/user'].roles;
  },
  permissions: function permissions(state) {
    return state['system/user'].permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  dict: function dict(state) {
    return state.dict.dict;
  },
  forms: function forms(state) {
    return state.formbuilder.forms;
  },
  activeForm: function activeForm(state) {
    return state.formbuilder.activeForm;
  },
  activeTabForFields: function activeTabForFields(state) {
    return state.formbuilder.activeTabForFields;
  },
  themingVars: function themingVars(state) {
    return state.formbuilder.themingVars;
  }
};
export default getters;