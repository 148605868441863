var state = {
  dict: []
};
var mutations = {
  SET_DICT: function SET_DICT(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (key !== null && key !== '') {
      state.dict.push({
        key: key,
        value: value
      });
    }
  },
  REMOVE_DICT: function REMOVE_DICT(state, key) {
    try {
      for (var i = 0; i < state.dict.length; i++) {
        if (state.dict[i].key === key) {
          state.dict.splice(i, 1);
          return true;
        }
      }
    } catch (e) {
      console.log();
    }
  },
  CLEAN_DICT: function CLEAN_DICT(state) {
    state.dict = [];
  }
};
var actions = {
  // 设置字典
  setDict: function setDict(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_DICT', data);
  },
  // 删除字典
  removeDict: function removeDict(_ref3, key) {
    var commit = _ref3.commit;
    commit('REMOVE_DICT', key);
  },
  // 清空字典
  cleanDict: function cleanDict(_ref4) {
    var commit = _ref4.commit;
    commit('CLEAN_DICT');
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};