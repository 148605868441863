import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';
export default function hasPermission(value) {
  var roles = store.getters && store.getters.roles;
  var hasAdmin = roles.some(function (role) {
    return 'admin'.includes(role);
  });

  if (hasAdmin) {// return true
  }

  if (value && value instanceof Array && value.length > 0) {
    var userPermissions = store.getters && store.getters.permissions;
    var permission = value;

    var _hasPermission = userPermissions.some(function (dbpermission) {
      return permission.includes(dbpermission);
    });

    if (!_hasPermission) {
      return false;
    }

    return true;
  } else {
    console.error("\u5F53\u524D\u64CD\u4F5C\u6CA1\u6709\u6743\u9650");
    return false;
  }
}