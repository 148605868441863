var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.currentField.options, function(item, index) {
      return _c(
        "el-radio",
        {
          key: index,
          attrs: { label: item.optionValue },
          model: {
            value: _vm.currentField.defaultValue,
            callback: function($$v) {
              _vm.$set(_vm.currentField, "defaultValue", $$v)
            },
            expression: "currentField.defaultValue"
          }
        },
        [_vm._v("\n    " + _vm._s(item.optionLabel) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }