import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'Properties',
  // store: ['activeForm'], // Get the form data from Home
  data: function data() {
    return {
      // activeForm:vm.$store.state.formbuilder.activeForm,
      labelPosition: 'top',
      fieldProperties: {},
      rules: {},
      imgoptions: [1, 2, 3, 4, 5],
      changeInputFlag: false,
      changeInputText: ''
    };
  },
  computed: _objectSpread({}, mapGetters(['activeForm'])),
  methods: {
    deleteOption: function deleteOption(option, index) {
      if (this.activeForm.fieldType === 'RadioButton' || this.activeForm.fieldType === 'SelectList') {
        if (option[index].optionValue === this.activeForm.defaultValue) {
          this.activeForm.defaultValue = '';
        }
      } else if (this.activeForm.fieldType === 'Checkbox') {
        this.activeForm.defaultValue.forEach(function (item, cindex, arr) {
          if (item === option[index].optionValue) {
            arr.splice(cindex, 1);
          }
        });
      } else if (this.activeForm.fieldType === 'RadioButtonOther') {
        this.activeForm.defaultValue.value = '';
      } else if (this.activeForm.fieldType === 'CheckboxOther') {
        this.activeForm.defaultValue.value.forEach(function (item, cindex, arr) {
          if (item === option[index].optionValue) {
            arr.splice(cindex, 1);
          }
        });
      }

      if (option[index].hasOwnProperty('optionOther')) {
        delete this.activeForm['optionOther'];
      }

      this.$delete(option, index);
    },
    changeValue: function changeValue() {
      if (this.activeForm.text === '') {
        this.activeForm.text = this.activeForm.label;
      }
    },
    addOption: function addOption(option) {
      var count = option.length + 1;
      console.log(option, this.activeForm);
      option.push({
        optionLabel: '选项 ' + count,
        optionValue: this.randomString()
      });
    },
    addOptionOther: function addOptionOther(option) {
      option.push({
        optionLabel: '其他',
        optionOther: true,
        // optionText: '',
        optionValue: this.randomString()
      });
      this.activeForm.optionOther = true;
    },

    /* radioInput(val){
    this.activeForm.defaultValue = val
    },*/
    checkInput: function checkInput(val) {
      // console.log('checkInput',val,this.activeForm.defaultValue,this.activeForm.options)
      this.activeForm.defaultValue[this.changeInputFlag] = val;
    },
    focusInput: function focusInput(val) {
      // console.log(val.target.value,this.activeForm.defaultValue.indexOf(val.target.value))
      if (this.activeForm.defaultValue.indexOf(val.target.value) !== -1) {
        this.changeInputFlag = this.activeForm.defaultValue.indexOf(val.target.value);
        this.changeInputText = val.target.value;
      } else {
        this.changeInputFlag = false;
      }
    },
    checkInputOther: function checkInputOther(val) {
      // console.log('checkInput',val,this.activeForm.defaultValue,this.activeForm.options)
      this.activeForm.defaultValue[this.changeInputFlag].value = val;
    },
    focusInputOther: function focusInputOther(val) {
      // console.log(val.target.value,this.activeForm.defaultValue.indexOf(val.target.value))
      if (this.activeForm.defaultValue.value.indexOf(val.target.value) !== -1) {
        this.changeInputFlag = this.activeForm.defaultValue.value.indexOf(val.target.value);
        this.changeInputText = val.target.value;
      } else {
        this.changeInputFlag = false;
      }
    },
    clickitem: function clickitem(e) {
      console.log(e);
      e === this.activeForm.defaultValue ? this.activeForm.defaultValue = '' : this.activeForm.defaultValue = e;
    },
    clickitemOther: function clickitemOther(e) {
      console.log(e);
      e === this.activeForm.defaultValue.value ? this.activeForm.defaultValue.value = '' : this.activeForm.defaultValue.value = e;
    },
    randomString: function randomString(e) {
      e = e || 4;
      var t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var a = t.length;
      var n = '';

      for (var i = 0; i < e; i++) {
        n += t.charAt(Math.floor(Math.random() * a));
      }

      return n;
    }
  }
};