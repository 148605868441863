var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "left" } },
        [
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeCollapse,
                callback: function($$v) {
                  _vm.activeCollapse = $$v
                },
                expression: "activeCollapse"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "Appearance", name: "appearance" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Font Family" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Select font family" },
                          model: {
                            value: _vm.themingVars.globalFontFamily,
                            callback: function($$v) {
                              _vm.$set(_vm.themingVars, "globalFontFamily", $$v)
                            },
                            expression: "themingVars.globalFontFamily"
                          }
                        },
                        _vm._l(_vm.fontOptions, function(group) {
                          return _c(
                            "el-option-group",
                            { key: group.label, attrs: { label: group.label } },
                            _vm._l(group.options, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Font Size" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 50 },
                        model: {
                          value: _vm.themingVars.globalFontSize,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "globalFontSize", $$v)
                          },
                          expression: "themingVars.globalFontSize"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.globalFontColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "globalFontColor", $$v)
                          },
                          expression: "themingVars.globalFontColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Link Color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.globalLinkColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "globalLinkColor", $$v)
                          },
                          expression: "themingVars.globalLinkColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-collapse-item",
                { attrs: { title: "Inputs", name: "inputs" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Border radius" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 50 },
                        model: {
                          value: _vm.themingVars.inputBorderRadius,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "inputBorderRadius", $$v)
                          },
                          expression: "themingVars.inputBorderRadius"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Border color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.inputBorderColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "inputBorderColor", $$v)
                          },
                          expression: "themingVars.inputBorderColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hover Border color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.inputHoverBorderColor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.themingVars,
                              "inputHoverBorderColor",
                              $$v
                            )
                          },
                          expression: "themingVars.inputHoverBorderColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Focus Border color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.inputFocusBorderColor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.themingVars,
                              "inputFocusBorderColor",
                              $$v
                            )
                          },
                          expression: "themingVars.inputFocusBorderColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Shadow color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.inputShadowColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "inputShadowColor", $$v)
                          },
                          expression: "themingVars.inputShadowColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-collapse-item",
                { attrs: { title: "Labels", name: "labels" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.primaryColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "primaryColor", $$v)
                          },
                          expression: "themingVars.primaryColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Font weight" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.themingVars.labelFontWeight,
                            callback: function($$v) {
                              _vm.$set(_vm.themingVars, "labelFontWeight", $$v)
                            },
                            expression: "themingVars.labelFontWeight"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 500 } }, [
                            _vm._v("Normal")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: 700 } }, [
                            _vm._v("Bold")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Font size" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 50 },
                        model: {
                          value: _vm.themingVars.labelFontSize,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "labelFontSize", $$v)
                          },
                          expression: "themingVars.labelFontSize"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Margin bottom" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 50 },
                        model: {
                          value: _vm.themingVars.labelMarginBottom,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "labelMarginBottom", $$v)
                          },
                          expression: "themingVars.labelMarginBottom"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-collapse-item",
                { attrs: { title: "Help Text", name: "helptext" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.helpTextColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "helpTextColor", $$v)
                          },
                          expression: "themingVars.helpTextColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Font size" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 50 },
                        model: {
                          value: _vm.themingVars.helpTextFontSize,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "helpTextFontSize", $$v)
                          },
                          expression: "themingVars.helpTextFontSize"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Margin top" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 50 },
                        model: {
                          value: _vm.themingVars.helpTextMarginTop,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "helpTextMarginTop", $$v)
                          },
                          expression: "themingVars.helpTextMarginTop"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-collapse-item",
                { attrs: { title: "Buttons", name: "buttons" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Background" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.buttonBackground,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "buttonBackground", $$v)
                          },
                          expression: "themingVars.buttonBackground"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Border color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.buttonBorderColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "buttonBorderColor", $$v)
                          },
                          expression: "themingVars.buttonBorderColor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.themingVars.buttonColor,
                          callback: function($$v) {
                            _vm.$set(_vm.themingVars, "buttonColor", $$v)
                          },
                          expression: "themingVars.buttonColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }