var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main__wrapper" },
    [
      _c(
        "el-container",
        [
          _c("el-main", { style: _vm.cssProps }, [
            _c(
              "div",
              { staticClass: "wrapper--forms preview__wrapper" },
              _vm._l(_vm.forms, function(form, index) {
                return _c(
                  "div",
                  _vm._b(
                    { key: index, staticClass: "form__group" },
                    "div",
                    form,
                    false
                  ),
                  [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: form.hasOwnProperty("label"),
                            expression: "form.hasOwnProperty('label')"
                          }
                        ],
                        staticClass: "form__label"
                      },
                      [_vm._v(_vm._s(index + 1) + "、" + _vm._s(form.text))]
                    ),
                    _vm._v(" "),
                    _c(form.fieldType, {
                      tag: "component",
                      staticClass: "form__field",
                      attrs: { "current-field": form }
                    }),
                    _vm._v(" "),
                    _c(
                      "small",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: form.isHelpBlockVisible,
                            expression: "form.isHelpBlockVisible"
                          }
                        ],
                        staticClass: "form__helpblock"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(form.helpBlockText) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }