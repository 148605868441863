import request from '@/utils/request';
export function getList(data) {
  return request({
    url: '/sys/menu/list',
    method: 'get',
    params: data
  });
}
export function nav() {
  return request({
    url: '/sys/menu/nav',
    method: 'get'
  });
}
export function getSelect() {
  return request({
    url: '/sys/menu/select',
    method: 'get'
  });
}
export function getMenuList() {
  return request({
    url: '/sys/menu/list/nothidden',
    method: 'get'
  });
}
export function get(data) {
  return request({
    url: '/sys/menu/info/' + data,
    method: 'get'
  });
}
export function create(data) {
  return request({
    url: 'sys/menu/save',
    method: 'post',
    data: data
  });
}
export function update(data) {
  return request({
    url: 'sys/menu/update',
    method: 'post',
    data: data
  });
}
export function del(data) {
  return request({
    url: '/sys/menu/delete/' + data,
    method: 'get'
  });
}