var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          rows: 2,
          placeholder: _vm.currentField.isPlaceholderVisible
            ? _vm.currentField.placeholder
            : ""
        },
        model: {
          value: _vm.textarea,
          callback: function($$v) {
            _vm.textarea = $$v
          },
          expression: "textarea"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }