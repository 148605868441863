var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.currentField.defaultValue.value,
            callback: function($$v) {
              _vm.$set(_vm.currentField.defaultValue, "value", $$v)
            },
            expression: "currentField.defaultValue.value"
          }
        },
        _vm._l(_vm.currentField.options, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("el-checkbox", { attrs: { label: item.optionValue } }, [
                _vm._v("\n        " + _vm._s(item.optionLabel) + "\n      ")
              ]),
              _vm._v(" "),
              item.optionOther
                ? _c("el-input", {
                    attrs: {
                      placeholder: _vm.currentField.isPlaceholderVisible
                        ? _vm.currentField.placeholder
                        : ""
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }