import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import Router from 'vue-router';
import { switchHump } from '@/utils';
/* Layout */

import Layout from '@/layout';
Vue.use(Router);
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '/recommend/success',
  component: function component() {
    return import('@/views/recommend/success');
  },
  name: '成功',
  meta: {
    title: '成功',
    icon: 'link',
    noCache: true,
    affix: false
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}, {
  path: '/user/center',
  component: Layout,
  redirect: '/user/center',
  hidden: true,
  children: [{
    path: '/user/center',
    component: function component() {
      return import('@/views/system/user/center');
    },
    name: '个人中心',
    meta: {
      title: '个人中心',
      icon: 'user',
      noCache: true,
      affix: false
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];
export function restAsyncRoutes() {
  asyncRoutes.splice(1, asyncRoutes.length);
}
export function generaMenu(accessedRoutes, data) {
  if (data) {
    data.forEach(function (item) {
      var menu = {
        path: item.url + '',
        component: Layout,
        name: item.component ? switchHump(item.component) : item.name,
        hidden: item.hidden === 0 ? false : true,
        meta: {
          title: item.name,
          icon: item.icon,
          noCache: false
        }
      }; // 有子菜单

      if (item.children) {
        menu.redirect = 'noredirect';
        menu.children = [];
        generaMenu(menu.children, item.children);
      } else {
        // 注册加载相应路由
        menu.component = loadView(item.component);
      } // 将菜单push进路由


      accessedRoutes.push(menu);
    });
  }
}

var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  restAsyncRoutes();
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default router;