//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'LongTextInput',
  props: ['currentField'],
  data: function data() {
    return {
      textarea: ''
    };
  }
};