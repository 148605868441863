import "core-js/modules/es6.regexp.to-string";
import _typeof from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
export function getFilterDate(day) {
  return [dateFormat(addDate(day == null ? -20 : day), 'yyyy-MM-dd'), dateFormat(addDate(0), 'yyyy-MM-dd')];
}
export function dateFormat(date, fmt) {
  var o = {
    'M+': date.getMonth() + 1,
    // 月份
    'd+': date.getDate(),
    // 日
    'h+': date.getHours(),
    // 小时
    'm+': date.getMinutes(),
    // 分
    's+': date.getSeconds(),
    // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3),
    // 季度
    S: date.getMilliseconds() // 毫秒

  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }

  return fmt;
}
export function customFormat(date, format) {
  if (typeof date === 'string') {
    var mts = date.match(/(\/Date\((\d+)\)\/)/);

    if (mts && mts.length >= 3) {
      date = parseInt(mts[2]);
    }
  }

  date = new Date(date);

  if (!date || date.toUTCString() === 'Invalid Date') {
    return '';
  }

  var map = {
    'M': date.getMonth() + 1,
    //月份
    'd': date.getDate(),
    //日
    'h': date.getHours(),
    //小时
    'm': date.getMinutes(),
    //分
    's': date.getSeconds(),
    //秒
    'q': Math.floor((date.getMonth() + 3) / 3),
    //季度
    'S': date.getMilliseconds() //毫秒

  };
  format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
    var v = map[t];

    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v;
        v = v.substr(v.length - 2);
      }

      return v;
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length);
    }

    return all;
  });
  return format;
}

function addDate(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
} // 添加日期范围


export function addDateRange(params, dateRange, propName) {
  var search = params;
  search.params = _typeof(search.params) === 'object' && search.params !== null && !Array.isArray(search.params) ? search.params : {};
  dateRange = Array.isArray(dateRange) ? dateRange : [];

  if (typeof propName === 'undefined') {
    search.params['beginTime'] = dateRange[0];
    search.params['endTime'] = dateRange[1];
  } else {
    search.params['begin' + propName] = dateRange[0];
    search.params['end' + propName] = dateRange[1];
  }

  return search;
} // 日期格式化

export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}