import "core-js/modules/es6.regexp.to-string";
import _toConsumableArray from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import Vue from 'vue';
import draggable from 'vuedraggable';
import RadioButton from '@/components/form_elements/FormElementRadioButton';
import RadioButtonOther from '@/components/form_elements/FormElementRadioButtonOther';
import Checkbox from '@/components/form_elements/FormElementCheckbox';
import CheckboxOther from '@/components/form_elements/FormElementCheckboxOther';
import TextInput from '@/components/form_elements/FormElementTextInput';
import LongTextInput from '@/components/form_elements/FormElementLongTextInput';
import DatePicker from '@/components/form_elements/FormElementDatePicker';
import DatetimePicker from '@/components/form_elements/FormElementDatetimePicker'; // add

import SectionBreak from '@/components/form_elements/FormElementSectionBreak';
import eImage from '@/components/form_elements/FormElementeImage';
import Elements from '@/components/form_elements/Elements';
import Properties from '@/components/form_elements/Properties';
import NumberInput from '@/components/form_elements/FormElementNumberInput';
import SelectList from '@/components/form_elements/FormElementSelectList';
import TimePicker from '@/components/form_elements/FormElementTimePicker';
import Rating from '@/components/form_elements/FormElementRating';
import Button from '@/components/form_elements/FormElementButton'; // import TextEditor			from '@/components/form_elements/FormElementTextEditor'

import Theming from '@/components/form_elements/Theming'; // import showform	 		 	from '@/components/form_elements/showform'

import TextEditor from '@/components/form_elements/FormElementTextEditor';
import NameInput from '@/components/form_elements/FormElementNameInput';
import PhoneInput from '@/components/form_elements/FormElementPhoneInput';
import EmailInput from '@/components/form_elements/FormElementEmailInput';
import GenderButton from '@/components/form_elements/FormElementGenderButton';
export var FormBuilder = new Vue({
  components: {
    Elements: Elements,
    Properties: Properties,
    Theming: Theming,
    draggable: draggable,
    TextInput: TextInput,
    LongTextInput: LongTextInput,
    NumberInput: NumberInput,
    SelectList: SelectList,
    RadioButton: RadioButton,
    RadioButtonOther: RadioButtonOther,
    Checkbox: Checkbox,
    CheckboxOther: CheckboxOther,
    TimePicker: TimePicker,
    DatePicker: DatePicker,
    DatetimePicker: DatetimePicker,
    Rating: Rating,
    Button: Button,
    SectionBreak: SectionBreak,
    eImage: eImage,
    TextEditor: TextEditor,
    NameInput: NameInput,
    PhoneInput: PhoneInput,
    EmailInput: EmailInput,
    GenderButton: GenderButton
  },
  data: function data() {
    return {
      fields: [{
        'name': 'RadioButton',
        'text': '单项选择',
        'icon': 'icondanxuan',
        'group': 'form',
        'isRequired': false,
        'helpBlockText': '' // 'tooltip': '适用于从多个备选项中选择一个'

      },
      /* {
        'name': 'RadioButtonOther',
        'text': '单选题',
        'icon': 'icondanxuan',
        'group': 'form',
        'isRequired': false,
        'helpBlockText': ''
        // 'tooltip': '适用于从多个备选项中选择一个'
      }, */
      {
        'name': 'Checkbox',
        'text': '多项选择',
        'icon': 'iconduoxuan',
        'group': 'form',
        'isRequired': false,
        'helpBlockText': '' // 'tooltip': '适用于从多个备选项中选择一个或多个'

      }, // {
      //   'name': 'CheckboxOther',
      //   'text': '多选题',
      //   'icon': 'iconduoxuan',
      //   'group': 'form',
      //   'isRequired': false,
      //   'helpBlockText': ''
      //   'tooltip': '适用于从多个备选项中选择一个或多个'
      // },
      {
        'name': 'TextInput',
        'text': '填空题',
        'icon': 'iconwenben',
        'group': 'form',
        'isRequired': false,
        'helpBlockText': ''
      }, {
        'name': 'LongTextInput',
        'text': '问答题',
        'icon': 'iconduohangwenben',
        'group': 'form',
        'isRequired': false,
        'helpBlockText': '' // 'tooltip': '适用于长篇幅文本表述的信息收集'

      }, {
        'name': 'SectionBreak',
        'text': '描述',
        'icon': 'icon-wenbenmiaoshu',
        'group': 'sectionbreak'
      }, // {
      //   'name': 'NameInput',
      //   'text': '姓名',
      //   'group': 'form',
      //   'isRequired': false,
      //   'isHelpBlockVisible': false,
      //   'isPlaceholderVisible': false
      // },
      // {
      //   'name': 'GenderButton',
      //   'text': '性别',
      //   'icon': 'icondanxuan',
      //   'group': 'form',
      //   'isRequired': false,
      //   'helpBlockText': ''
      // },
      // {
      //   'name': 'PhoneInput',
      //   'text': '手机',
      //   'group': 'form',
      //   'isRequired': false,
      //   'isHelpBlockVisible': false,
      //   'isPlaceholderVisible': false
      // },
      // {
      //   'name': 'EmailInput',
      //   'text': '邮箱',
      //   'group': 'form',
      //   'isRequired': false,
      //   'isHelpBlockVisible': false,
      //   'isPlaceholderVisible': false
      // },
      {
        'name': 'DatePicker',
        'text': '日期',
        'group': 'form',
        // 'tooltip': '用于日期信息的收集，年-月-日',
        'isHelpBlockVisible': false,
        'isPlaceholderVisible': false,
        'isRequired': false
      }, {
        'name': 'DatetimePicker',
        'text': '日期时间',
        'group': 'form',
        // 'tooltip': '用于日期+时间信息的收集，年-月-日 时:分:秒',
        'isHelpBlockVisible': false,
        'isPlaceholderVisible': false,
        'isRequired': false
      }, {
        'name': 'eImage',
        'text': '图片',
        'icon': 'icontupian',
        'group': 'img',
        'isHelpBlockVisible': false,
        'isPlaceholderVisible': false,
        'isRequired': false
      }
      /*{
        'name': 'Rating',
        'text': '评分',
        'group': 'form',
        'isRequired': false,
        'isHelpBlockVisible': false,
        'isPlaceholderVisible': false
      }, {
        'name': 'TextEditor',
        'text': '图文',
        'group': 'form',
        'isRequired': false,
        'isHelpBlockVisible': false,
        'isPlaceholderVisible': false
      }*/
      ],
      sortElementOptions: {
        group: {
          name: 'formbuilder',
          pull: false,
          put: true
        },
        sort: true,
        handle: '.form__actionitem--move'
      },
      dropElementOptions: {
        group: {
          name: 'formbuilder',
          pull: 'clone',
          put: false
        },
        sort: false,
        ghostClass: 'sortable__ghost',
        filter: '.is-disabled'
      }
    };
  },
  methods: {
    deleteElement: function deleteElement(index) {
      // console.log('deleteElement')
      // window.vm.$store.state.formbuilder.activeForm = [];
      // window.vm.$store.state.formbuilder.activeTabForFields = "elements";
      window.vm.$store.dispatch('formbuilder/setActiveForm', []);
      window.vm.$store.dispatch('formbuilder/setActiveTabForFields', 'elements');
      this.$delete(window.vm.$store.state.formbuilder.forms, index);
    },
    cloneElement: function cloneElement(index, form) {
      // console.log('cloneElement',index,form)
      // var cloned = _.cloneDeep(form) // clone deep lodash
      // window.vm.$store.state.formbuilder.forms.splice(index, 0, form)
      // console.log(this)
      var cloned = this._deepClone(form);

      cloned.inputname = 'input_' + new Date().getTime(); // console.log(cloned)

      window.vm.$store.state.formbuilder.forms.splice(index + 1, 0, cloned); // console.log('formbuilder/setActiveForm',form,cloned)

      window.vm.$store.dispatch('formbuilder/setActiveForm', cloned);
    },
    moveUpElement: function moveUpElement(index) {
      // console.log('moveUpElement',index)
      if (index > 0) {
        var _window$vm$$store$sta;

        (_window$vm$$store$sta = window.vm.$store.state.formbuilder.forms).splice.apply(_window$vm$$store$sta, [index - 1, 1].concat(_toConsumableArray(window.vm.$store.state.formbuilder.forms.splice(index, 1, window.vm.$store.state.formbuilder.forms[index - 1]))));
      } else {
        console.log('不可移动');
      }
    },
    moveDownElement: function moveDownElement(index) {
      // console.log('moveDownElement',index)
      if (window.vm.$store.state.formbuilder.forms.length - 1 > index) {
        var _window$vm$$store$sta2;

        (_window$vm$$store$sta2 = window.vm.$store.state.formbuilder.forms).splice.apply(_window$vm$$store$sta2, [index + 1, 1].concat(_toConsumableArray(window.vm.$store.state.formbuilder.forms.splice(index, 1, window.vm.$store.state.formbuilder.forms[index + 1]))));
      } else {
        console.log('不可移动');
      }
    },
    editElementProperties: function editElementProperties(form) {
      // console.log('editElementProperties',form.fieldType)
      // window.vm.$store.state.formbuilder.activeForm = form;
      // window.vm.$store.state.formbuilder.activeTabForFields = "properties";
      if (form.fieldType === 'RadioButtonOther' || form.fieldType === 'CheckboxOther') {
        for (var i = 0; i < form.options.length; i++) {
          if (form.options[i].optionOther) {
            form.optionOther = true;
            break;
          }
        }
      }

      window.vm.$store.dispatch('formbuilder/setActiveForm', form);
      window.vm.$store.dispatch('formbuilder/setActiveTabForFields', 'properties');
    },
    _deepClone: function _deepClone(obj) {
      // 先检测是不是数组和Object
      // let isArr = Object.prototype.toString.call(obj) === '[object Array]';
      var isArr = Array.isArray(obj);
      var isJson = Object.prototype.toString.call(obj) === '[object Object]';

      if (isArr) {
        // 克隆数组
        var newObj = [];

        for (var i = 0; i < obj.length; i++) {
          newObj[i] = this._deepClone(obj[i]);
        }

        return newObj;
      } else if (isJson) {
        // 克隆Object
        var _newObj = {};

        for (var _i in obj) {
          _newObj[_i] = this._deepClone(obj[_i]);
        }

        return _newObj;
      } // 不是引用类型直接返回


      return obj;
    }
  }
});