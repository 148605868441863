import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
import { mapGetters } from 'vuex'; // import tinyEditor from '@/components/tinymce/tinymce'

export default {
  name: 'TextEditor',
  components: {// tinyEditor
  },
  props: ['currentField'],
  data: function data() {
    return {
      date: ''
    };
  },
  computed: _objectSpread({}, mapGetters(['imagesUploadApi', 'baseApi'])),
  mounted: function mounted() {// var editor = new E(this.$refs.editor)
    // // 自定义菜单配置
    // editor.config.zIndex = 1
    // // 文件上传
    // editor.config.customUploadImg = function(files, insert) {
    //   // files 是 input 中选中的文件列表
    //   // insert 是获取图片 url 后，插入到编辑器的方法
    //   files.forEach(image => {
    //     uploadOss(image).then(res => {
    //       // const data = res.data
    //       // const url = _this.baseApi + '/file/' + data.type + '/' + data.realName
    //       // console.log(res.res, signatureUrl(image.name))
    //       signatureUrl(image.name).then(res => {
    //         console.log(res)
    //         insert(res)
    //       })
    //       // insert(res.res.requestUrls[0])
    //     })
    //   })
    // }
    // editor.config.onchange = (html) => {
    //   // this.currentField.inputname = html
    // }
    // editor.create()
  }
};