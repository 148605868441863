var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "EmailInput" }, [
    _c(
      "div",
      { staticClass: "inputbox" },
      [
        _c("svg-icon", {
          staticClass: "icon",
          attrs: { "icon-class": "email-ly" }
        }),
        _vm._v(" "),
        _c("el-input", {
          staticStyle: { flex: "1" },
          attrs: {
            placeholder: _vm.currentField.isPlaceholderVisible
              ? _vm.currentField.placeholder
              : ""
          }
        }),
        _vm._v(" "),
        _c("div")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }