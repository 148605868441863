import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BackToTop',
  props: {
    visibilityHeight: {
      type: Number,
      default: 400
    },
    backPosition: {
      type: Number,
      default: 0
    },
    customStyle: {
      type: Object,
      default: function _default() {
        return {
          right: '50px',
          bottom: '50px',
          width: '40px',
          height: '40px',
          'border-radius': '4px',
          'line-height': '45px',
          background: '#e7eaf1'
        };
      }
    },
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data: function data() {
    return {
      visible: false,
      interval: null,
      isMoving: false
    };
  },
  mounted: function mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);

    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    handleScroll: function handleScroll() {
      this.visible = window.pageYOffset > this.visibilityHeight;
    },
    backToTop: function backToTop() {
      var _this = this;

      if (this.isMoving) return;
      var start = window.pageYOffset;
      var i = 0;
      this.isMoving = true;
      this.interval = setInterval(function () {
        var next = Math.floor(_this.easeInOutQuad(10 * i, start, -start, 500));

        if (next <= _this.backPosition) {
          window.scrollTo(0, _this.backPosition);
          clearInterval(_this.interval);
          _this.isMoving = false;
        } else {
          window.scrollTo(0, next);
        }

        i++;
      }, 16.7);
    },
    easeInOutQuad: function easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b;
      return -c / 2 * (--t * (t - 2) - 1) + b;
    }
  }
};