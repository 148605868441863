//
//
//
//
//
//
//
//
export default {
  name: 'TextInput',
  props: ['currentField']
};