//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DatetimePicker',
  data: function data() {
    return {
      date: ''
    };
  }
};