import { checkPermission } from '@/utils/auth';
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */

export default {
  install: function install(Vue) {
    Vue.prototype.checkPer = function (value) {
      return checkPermission(value);
    };
  }
};