var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "protitle" }, [_vm._v("题目编辑")]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeForm.hasOwnProperty("label"),
            expression: "activeForm.hasOwnProperty('label')"
          }
        ],
        staticClass: "textTitle"
      },
      [_vm._v(_vm._s(_vm.activeForm.label))]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "el-tabs__inner" },
      [
        _c(
          "el-form",
          {
            ref: "fieldProperties",
            attrs: {
              model: _vm.fieldProperties,
              rules: _vm.rules,
              "label-position": _vm.labelPosition
            }
          },
          [
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("text"),
                    expression: "activeForm.hasOwnProperty('text')"
                  }
                ]
              },
              [
                _c(
                  "label",
                  { attrs: { for: "" } },
                  [
                    _vm._v("问题 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "此属性用于告诉填写者应该在该字段中输入什么样的内容。",
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-warning-outline" }, [
                          _vm._v(" ("),
                          _c("span", { staticStyle: { color: "#f00" } }, [
                            _vm._v("*")
                          ]),
                          _vm._v(")")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    on: { blur: _vm.changeValue },
                    model: {
                      value: _vm.activeForm.text,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "text", $$v)
                      },
                      expression: "activeForm.text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.activeForm.text))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("breakText"),
                    expression: "activeForm.hasOwnProperty('breakText')"
                  }
                ],
                attrs: { label: "描述文本" }
              },
              [
                _c(
                  "el-input",
                  {
                    model: {
                      value: _vm.activeForm.breakText,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "breakText", $$v)
                      },
                      expression: "activeForm.breakText"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.activeForm.breakText) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("helpBlockText"),
                    expression: "activeForm.hasOwnProperty('helpBlockText')"
                  }
                ],
                attrs: { label: "提示" }
              },
              [
                _c("div", { staticClass: "helptips" }, [
                  _vm._v(
                    "此属性用于指定对该字段进行一些附加说明，一般用来指导填写者输入。"
                  )
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { type: "textarea" },
                  model: {
                    value: _vm.activeForm.helpBlockText,
                    callback: function($$v) {
                      _vm.$set(_vm.activeForm, "helpBlockText", $$v)
                    },
                    expression: "activeForm.helpBlockText"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("isRequired"),
                    expression: "activeForm.hasOwnProperty('isRequired')"
                  }
                ],
                staticClass: "el-form-item el-form-item--mini",
                staticStyle: {
                  display: "flex",
                  "padding-top": "3px",
                  "margin-bottom": "0"
                }
              },
              [
                _c("label", { staticClass: "el-form-item__label" }, [
                  _vm._v("是否必填？")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "el-form-item__content" },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.activeForm.isRequired,
                        callback: function($$v) {
                          _vm.$set(_vm.activeForm, "isRequired", $$v)
                        },
                        expression: "activeForm.isRequired"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _vm.activeForm.fieldType === "RadioButton" ||
            _vm.activeForm.fieldType === "RadioButtonOther" ||
            _vm.activeForm.fieldType === "SelectList" ||
            _vm.activeForm.fieldType === "Checkbox" ||
            _vm.activeForm.fieldType === "CheckboxOther"
              ? _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeForm.options,
                        expression: "activeForm.options"
                      }
                    ],
                    attrs: { label: "选择" }
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.activeForm.options, function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "properties__optionslist"
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 5 } },
                              [
                                _vm.activeForm.fieldType === "RadioButton" ||
                                _vm.activeForm.fieldType === "SelectList"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        item.optionLabel ===
                                        _vm.activeForm.defaultValue
                                          ? _c(
                                              "el-input",
                                              {
                                                model: {
                                                  value: item.optionLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "optionLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.optionLabel"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.optionLabel))]
                                            )
                                          : _c(
                                              "el-input",
                                              {
                                                model: {
                                                  value: item.optionLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "optionLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.optionLabel"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.optionLabel))]
                                            )
                                      ],
                                      1
                                    )
                                  : _vm.activeForm.fieldType ===
                                    "RadioButtonOther"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        item.optionLabel ===
                                        _vm.activeForm.defaultValue.value
                                          ? _c(
                                              "el-input",
                                              {
                                                model: {
                                                  value: item.optionLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "optionLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.optionLabel"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.optionLabel))]
                                            )
                                          : _c(
                                              "el-input",
                                              {
                                                model: {
                                                  value: item.optionLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "optionLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.optionLabel"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.optionLabel))]
                                            )
                                      ],
                                      1
                                    )
                                  : _vm.activeForm.fieldType === "Checkbox"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            on: {
                                              focus: _vm.focusInput,
                                              input: _vm.checkInput
                                            },
                                            model: {
                                              value: item.optionLabel,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "optionLabel",
                                                  $$v
                                                )
                                              },
                                              expression: "item.optionLabel"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.optionLabel))]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm.activeForm.fieldType === "CheckboxOther"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            on: {
                                              focus: _vm.focusInputOther,
                                              input: _vm.checkInputOther
                                            },
                                            model: {
                                              value: item.optionLabel,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "optionLabel",
                                                  $$v
                                                )
                                              },
                                              expression: "item.optionLabel"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.optionLabel))]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.activeForm.options.length > 1,
                                            expression:
                                              "activeForm.options.length > 1"
                                          }
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteOption(
                                              _vm.activeForm.options,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.addOption(_vm.activeForm.options)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v("\n            添加项\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    (_vm.activeForm.fieldType === "RadioButtonOther" ||
                      _vm.activeForm.fieldType === "CheckboxOther") &&
                    !_vm.activeForm.optionOther
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.addOptionOther(
                                  _vm.activeForm.options
                                )
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v("\n            其他项\n          ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty(
                      "isPlaceholderVisible"
                    ),
                    expression:
                      "activeForm.hasOwnProperty('isPlaceholderVisible')"
                  }
                ],
                attrs: { label: "占位符" }
              },
              [
                _c("div", { staticClass: "helptips" }, [
                  _vm._v(
                    "设置后，此值将作为默认值显示在该字段的输入框中。如果不需要设置默认值，请将此处留空。"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    model: {
                      value: _vm.activeForm.placeholder,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "placeholder", $$v)
                      },
                      expression: "activeForm.placeholder"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.activeForm.placeholder) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("buttonText"),
                    expression: "activeForm.hasOwnProperty('buttonText')"
                  }
                ],
                attrs: { label: "按钮文本" }
              },
              [
                _c(
                  "el-input",
                  {
                    model: {
                      value: _vm.activeForm.buttonText,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "buttonText", $$v)
                      },
                      expression: "activeForm.buttonText"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.activeForm.buttonText) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.hasOwnProperty("fieldText"),
                    expression: "activeForm.hasOwnProperty('fieldText')"
                  }
                ],
                attrs: { label: "代码视图" }
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: { type: "textarea", rows: 10 },
                    model: {
                      value: _vm.activeForm.fieldText,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "fieldText", $$v)
                      },
                      expression: "activeForm.fieldText"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.activeForm.fieldText) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeForm.fieldType === "eImage",
                    expression: "activeForm.fieldType === 'eImage'"
                  }
                ],
                attrs: { label: "最大图片数量" }
              },
              [
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.activeForm.imglength,
                      callback: function($$v) {
                        _vm.$set(_vm.activeForm, "imglength", $$v)
                      },
                      expression: "activeForm.imglength"
                    }
                  },
                  _vm._l(_vm.imgoptions, function(item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }