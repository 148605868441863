import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import Vue from 'vue';
import store from '@/store';
import { isString, isArray } from '@/utils/validate';
import settings from '@/settings'; // you can set in settings.js
// errorLog:'production' | ['production','development']

var needErrorLog = settings.errorLog;

function checkNeed(arg) {
  var env = process.env.NODE_ENV;

  if (isString(needErrorLog)) {
    return env === needErrorLog;
  }

  if (isArray(needErrorLog)) {
    return needErrorLog.includes(env);
  }

  return false;
}

if (checkNeed()) {
  Vue.config.errorHandler = function (err, vm, info, a) {
    // Don't ask me why I use Vue.nextTick, it just a hack.
    // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
    Vue.nextTick(function () {
      store.dispatch('errorLog/addErrorLog', {
        err: err,
        vm: vm,
        info: info,
        url: window.location.href
      });
      console.error(err, info);
    });
  };
}