import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
export function getCodeImg() {
  return request({
    url: '/auth/code',
    method: 'get'
  });
}
export function getVerification(data) {
  return request({
    url: '/auth/smscode',
    method: 'post',
    data: data
  });
}