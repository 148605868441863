var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "filedtitle" },
      [
        _vm._v("添加问题\n    "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content:
                "选择合适的题目类型，点击添加到问卷底部，或拖拽到希望添加的位置",
              placement: "right"
            }
          },
          [_c("i", { staticClass: "el-icon-warning-outline" })]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "el-tabsfiled" },
      [
        _c(
          "draggable",
          {
            staticClass: "dragArea",
            attrs: {
              list: _vm.fields,
              clone: _vm.clone,
              options: _vm.dropElementOptions
            },
            on: { start: _vm.onStart }
          },
          _vm._l(_vm.fields, function(field, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "inputbox",
                class: { "is-disabled": _vm.checkStopDragCondition(field) },
                on: {
                  click: function($event) {
                    return _vm.createForm(field)
                  }
                }
              },
              [
                _c("span", { staticClass: "iconfont", class: field.icon }),
                _c("span", [_vm._v(_vm._s(field.text))]),
                _vm._v(" "),
                field.tooltip
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: field.tooltip,
                          placement: "right"
                        }
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }