module.exports = {
  title: '银川雀诊互联网医院',

  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,

  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天
   */
  passCookieExpires: 1,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'development',

  /**
   * 是否显示设置的底部信息
   */
  showFooter: false,

  /**
   * 底部文字，支持html语法
   */
  footerTxt: 'copy © 2023. 银川雀诊互联网医院有限公司 All Rights Reserved.',

  /**
   * 备案号
   */
  caseNumber: '宁ICP备01238876号',

  /**
   * 是否隐藏右侧控制面板
   */
  showRightPanel: false,

  /**
   * 设置按钮
   */
  settingBtn: false,

  /**
   * 菜单是否只打开一个
   */
  uniqueOpened: true,

  /**
   * 水印
   */
  warterMark: true
};