import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _defineProperty from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { FormBuilder } from '@/components/form_elements/formbuilder';
import draggable from 'vuedraggable';
export default {
  name: 'Elements',
  // store: ['forms', 'activeForm'],
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      // forms:window.vm.$store.state.formbuilder.forms,
      // activeForm:window.vm.$store.state.formbuilder.activeForm,
      fields: FormBuilder.$data.fields,
      dropElementOptions: FormBuilder.$data.dropElementOptions
    };
  },
  computed: _objectSpread({}, mapGetters(['forms', 'activeForm'])),
  methods: {
    createForm: function createForm(field) {
      console.log('createForm', field, window.vm.$store.state.formbuilder.activeForm, this);
      var index = 0;

      for (var i = 0; i < window.vm.$store.state.formbuilder.forms.length; i++) {
        if (window.vm.$store.state.formbuilder.forms[i] === window.vm.$store.state.formbuilder.activeForm) {
          // console.log(i,'===window.vm.$store.state.formbuilder.activeForm')
          index = i;
        }
      }

      window.vm.$store.state.formbuilder.forms.splice(index + 1, 0, this.clone(field)); // window.vm.$store.state.formbuilder.forms.push(this.clone(field));
    },
    clone: function clone(field) {
      var newField = {
        fieldType: field.name,
        inputname: 'input_' + new Date().getTime(),
        label: field.text,
        text: field.text // isRequired:field.isRequired,
        // helpBlockText:field.helpBlockText,
        // hasOptions:field.hasOptions,
        // isUnique: field.isUnique,

      };

      if (field.name !== 'SectionBreak') {
        newField.isRequired = field.isRequired;
        newField.helpBlockText = field.helpBlockText;
      } // var newField  = {
      //   fieldType: field.name,
      //   label:field.text,
      //   isUnique: field.isUnique,
      //   inputname: 'input_' + new Date().getTime()
      // }
      // if (field.name === 'TextInput') {
      // newField['isTitle'] = false
      // newField ["isTitle"] = true;
      // for(var i=0;i<this.forms.length;i++){
      //   if(this.forms[i].fieldType ==== 'TextInput' && this.forms[i].isTitle){
      //     newField ["isTitle"] = false;
      //     console.log(this.forms[i])
      //     continue;
      //   }
      // }
      // }
      // Show placeholder


      if (field.name === 'TextInput' || field.name === 'LongTextInput') {
        newField['isPlaceholderVisible'] = true;
        newField['placeholder'] = '';
      } // Decide whether display label, required field, helpblock
      // if (field.group === "form"){
      // newField ["label"] = "单选题";
      // newField ["isHelpBlockVisible"] =  false;
      // newField ["helpBlockText"] = '此属性用于指定对该字段进行一些附加说明，一般用来指导填写者输入。';
      // newField ["isRequired"] = false;
      // }
      // if (field.group === "button"){
      //   newField ["buttonText"] = "提交您的表格";
      // }
      // if (field.name === "TextEditor"){
      //   newField ["fieldText"] = "开始打字...";
      // }


      if (field.name === 'SectionBreak') {
        newField['label'] = '说明';
        newField['breakText'] = '请在右侧面板添加段落说明信息';
      }

      if (field.name === 'eImage') {
        newField['imglength'] = 1;
      } // Add dummy options for loading the radio/checkbox


      if (field.name === 'RadioButton' || field.name === 'RadioButtonOther' || field.name === 'Checkbox' || field.name === 'CheckboxOther' || field.name === 'SelectList') {
        newField['options'] = [{
          optionLabel: '选项1',
          optionValue: this.randomString()
        }, {
          optionLabel: '选项2',
          optionValue: this.randomString()
        }];
      }

      if (field.name === 'RadioButton' || field.name === 'RadioButtonOther' || field.name === 'SelectList') {
        newField.defaultValue = '';
      }

      if (field.name === 'RadioButtonOther') {
        newField.defaultValue = {
          value: ''
        };
      }

      if (field.name === 'CheckboxOther') {
        newField.defaultValue = {
          value: []
        };
      }

      if (field.name === 'GenderButton') {
        newField['options'] = [{
          optionLabel: '男',
          optionValue: 1
        }, {
          optionLabel: '女',
          optionValue: 2
        }];
      }

      if (field.name === 'GenderButton') {
        newField.defaultValue = '';
      }

      if (field.name === 'Checkbox') {
        newField.defaultValue = [];
      }

      console.log('formbuilder/setActiveForm', newField);
      window.vm.$store.dispatch('formbuilder/setActiveForm', newField);
      return newField;
    },
    onStart: function onStart() {
      console.log('start liao');
    },
    checkStopDragCondition: function checkStopDragCondition(field) {
      var form = this.forms;
      var formArray = [];

      for (var key in form) {
        formArray.push(form[key]['fieldType']);
      } // Check if the fieldname exist in formArray
      // And when the field.isUnique too


      return this._includes(formArray, field.name) && field.isUnique; // return field.isUnique;
    },
    _includes: function _includes(arr, val) {
      var testStr = ',' + arr.join(',') + ',';
      return testStr.indexOf(',' + val + ',') !== -1;
    },
    randomString: function randomString(e) {
      e = e || 4;
      var t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var a = t.length;
      var n = '';

      for (var i = 0; i < e; i++) {
        n += t.charAt(Math.floor(Math.random() * a));
      }

      return n;
    }
  }
};