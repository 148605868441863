import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/spital-cms-admin_quezhen-develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'Theming',
  // store: ['themingVars'],
  data: function data() {
    return {
      // themingVars:vm.$store.state.formbuilder.themingVars,
      activeCollapse: ['appearance'],
      fontOptions: [{
        label: 'Sans Serif',
        options: [{
          value: 'Arial',
          label: 'Arial'
        }, {
          value: 'Arial Black',
          label: 'Arial Black'
        }, {
          value: 'Tahoma',
          label: 'Tahoma'
        }, {
          value: 'Trebuchet MS',
          label: 'Trebuchet MS'
        }, {
          value: 'Verdana',
          label: 'Verdana'
        }]
      }, {
        label: 'Serif ',
        options: [{
          value: 'Georgia',
          label: 'Georgia'
        }, {
          value: 'Times',
          label: 'Times'
        }, {
          value: 'Times New Roman',
          label: 'Times New Roman'
        }]
      }, {
        label: 'Monospace',
        options: [{
          value: 'Courier',
          label: 'Courier'
        }, {
          value: 'Courier New',
          label: 'Courier New'
        }]
      }]
    };
  },
  computed: _objectSpread({}, mapGetters(['themingVars']))
};